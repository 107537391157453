.sec-10 {
  margin-bottom: 20px;

  .container-fluid {
    .wrapper {
      padding: 52px 0;
      border-radius: 8px;
      background: #1f2937;

      h2 {
        color: #f9fafb;
        text-align: center;

        /* Desktop/h3 */
        font-family: 'Sofia Sans';
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px; /* 133.333% */
        margin-bottom: 48px;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px; /* 150% */
        }
      }

      .swiper {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
      }

      .swiper-slide {
        border-radius: 8px;
        background: #111827;
        box-shadow: 0px 5px 20px 0px rgba(17, 24, 28, 0.12);
        width: 80px;
        height: 80px;
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h3 {
        color: #f9fafb;
        text-align: center;

        /* Desktop/h5 */
        font-family: 'Sofia Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
      }
    }
  }
}
